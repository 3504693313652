<template>
  <mobile-screen
    :header="true"
    screen-class="resources-screen gray-bg icon-app1"
  >
    <template v-slot:header>
      <top-header-menu-wrapper
        menu-class="resource-header icon-hea1"
        :helpOnline="
          Boolean(
            helpOnline[helpSlug] &&
              helpOnline[helpSlug].name &&
              helpOnline[helpSlug].content
          )
        "
        :helpOnlineRoute="{
          name: 'r_resources-help-online-edit'
        }"
      >
        <template v-slot:left>
          <router-link
            :to="{
              name: 'r_resources',
              params: $route.params
            }"
          >
            <icon icon="#cx-hea1-arrow-left" />
          </router-link>
        </template>
        <div class="component-title">
          {{ displayLabelName("resources", "edit-resource", "edit-resource") }}
        </div>
        <template v-slot:right>
          <button
            v-if="
              canItem(resource && resource.data, 'update') &&
                $route.name == 'r_edit-resource'
            "
            @click="initSubmit"
          >
            <icon icon="#cx-hea1-save"></icon>
          </button>
        </template>
      </top-header-menu-wrapper>
    </template>
    <VeeForm
      :key="formKey"
      v-if="resource && resource.data"
      ref="editResourceForm"
      @submit="submitForm"
      v-slot="{ errors }"
      class="form inline-input edit-form"
      :initial-values="resource.data"
      novalidate
    >
      <ul class="clebex-item-section">
        <!-- Resource Id -->
        <li class="clebex-item-section-item full-right-underline">
          <div class="clebex-item-content-wrapper">
            <div class="clebex-section-input">
              <div class="clebex-section-input-label">
                {{ displayLabelName("resources", "edit-resource", "id") }}
              </div>
              <p class="text-label">{{ resource.data.id }}</p>
            </div>
          </div>
        </li>
        <!-- Resource Name -->
        <li
          class="clebex-item-section-item full-right-underline"
          :class="{ 'has-error': errors.has_translations }"
        >
          <div class="error-message" v-if="errors.has_translations">
            {{ errors.has_translations }}
          </div>
          <div class="clebex-item-content-wrapper">
            <div class="clebex-section-input">
              <div class="clebex-section-input-label">
                {{ displayLabelName("resources", "edit-resource", "name") }}
              </div>
              <p class="text-label">
                {{ displayResourceSavedName || displayResourceName }}
              </p>
            </div>
            <span class="follow-up-icons">
              <router-link
                :to="{
                  name: 'r_edit-resource-translate-name',
                  params: $route.params
                }"
                class="follow-up-icon-item"
              >
                <icon icon="#cx-men1-translations" />
              </router-link>
            </span>
          </div>
          <Field
            name="has_translations"
            as="input"
            type="hidden"
            rules="required"
            :value="hasTranslationsClause"
          />
        </li>
        <!-- Resource Level -->
        <li class="clebex-item-section-item full-right-underline">
          <router-link
            :to="
              canItem(resource.data, 'update')
                ? {
                    name: 'r_edit-resource-select-level',
                    params: $route.params
                  }
                : ''
            "
            class="clebex-item-content-wrapper"
          >
            <div
              class="clebex-section-input"
              v-if="selectedResourceLevel && selectedResourceLevel.name"
            >
              <label class="clebex-section-input-label">{{
                displayLabelName("resources", "edit-resource", "level-id")
              }}</label>
              <p class="text-label">
                {{ selectedResourceLevel.name }}
              </p>
            </div>
            <div class="label" v-else>
              {{ displayLabelName("resources", "edit-resource", "level-id") }}
            </div>
            <span class="follow-up-icons">
              <span class="follow-up-icon-item">
                <icon
                  icon="#cx-app1-arrow-right-12x12"
                  width="12"
                  height="12"
                />
              </span>
            </span>
          </router-link>
          <Field name="level_id" as="input" type="hidden" v-model="level_id" />
        </li>
        <!-- Resource Type -->
        <li
          class="clebex-item-section-item full-right-underline"
          :class="{ 'has-error': errors.resource_type_id }"
        >
          <div class="error-message" v-if="errors.resource_type_id">
            {{ errors.resource_type_id }}
          </div>
          <router-link
            :to="
              canItem(resource.data, 'update')
                ? {
                    name: 'r_edit-resource-select-resource-type',
                    params: $route.params
                  }
                : ''
            "
            class="clebex-item-content-wrapper"
          >
            <div
              class="clebex-section-input"
              v-if="selectedResourceType && selectedResourceType.name"
            >
              <label class="clebex-section-input-label">{{
                displayLabelName(
                  "resources",
                  "edit-resource",
                  "resource-type-id"
                )
              }}</label>
              <p class="text-label">
                {{ selectedResourceType.name }}
              </p>
            </div>
            <div class="label" v-else>
              {{
                displayLabelName(
                  "resources",
                  "edit-resource",
                  "resource-type-id"
                )
              }}
            </div>
            <span class="follow-up-icons">
              <span class="follow-up-icon-item">
                <icon
                  icon="#cx-app1-arrow-right-12x12"
                  width="12"
                  height="12"
                />
              </span>
            </span>
            <Field
              name="resource_type_id"
              rules="required"
              as="input"
              type="hidden"
              v-model="resource_type_id"
            />
          </router-link>
        </li>
        <!-- Resource Function -->
        <li class="clebex-item-section-item full-right-underline">
          <router-link
            :to="
              canItem(resource.data, 'update')
                ? {
                    name: 'r_edit-resource-select-function',
                    params: $route.params
                  }
                : ''
            "
            class="clebex-item-content-wrapper"
          >
            <!--            activeResourceFunctionTypes -->
            <div
              class="clebex-section-input"
              v-if="
                activeResourceFunctionTypes &&
                  activeResourceFunctionTypes.length
              "
            >
              <div class="clebex-section-input-label">
                {{
                  displayLabelName(
                    "resources",
                    "edit-resource",
                    "resource-function"
                  )
                }}
              </div>
              <div class="text-label">
                {{ displayResourceFunctions() }}
              </div>
            </div>
            <div class="label" v-else>
              {{
                displayLabelName(
                  "resources",
                  "edit-resource",
                  "resource-function"
                )
              }}
            </div>
            <span class="follow-up-icons">
              <span class="follow-up-icon-item">
                <icon
                  icon="#cx-app1-arrow-right-12x12"
                  width="12"
                  height="12"
                />
              </span>
            </span>
          </router-link>
        </li>
        <!-- Resource Capacity -->
        <li class="clebex-item-section-item full-right-underline">
          <router-link
            :to="
              canItem(resource.data, 'update')
                ? {
                    name: 'r_edit-resource-select-resource-capacity',
                    params: $route.params
                  }
                : ''
            "
            class="clebex-item-content-wrapper"
          >
            <div class="clebex-section-input" v-if="capacity">
              <label class="clebex-section-input-label">{{
                displayLabelName("resources", "edit-resource", "capacity")
              }}</label>
              <p class="text-label">{{ capacity }}</p>
            </div>
            <div class="label" v-else>
              {{ displayLabelName("resources", "edit-resource", "capacity") }}
            </div>
            <span class="follow-up-icons">
              <span class="follow-up-icon-item">
                <icon
                  icon="#cx-app1-arrow-right-12x12"
                  width="12"
                  height="12"
                />
              </span>
            </span>
            <Field
              name="capacity"
              as="input"
              type="hidden"
              v-model="capacity"
            />
            <Field
              name="max_capacity"
              as="input"
              type="hidden"
              v-model="max_capacity"
            />
            <Field
              name="resource_capacity_type_id"
              as="input"
              type="hidden"
              v-model="resource_capacity_type_id"
            />
          </router-link>
        </li>
        <!-- Resource Model -->
        <li
          class="clebex-item-section-item full-right-underline"
          :class="{ 'has-error': errors.resource_model_id }"
        >
          <div class="error-message" v-if="errors.resource_model_id">
            {{ errors.resource_model_id }}
          </div>
          <router-link
            :to="
              canItem(resource.data, 'update')
                ? {
                    name: 'r_edit-resource-select-resource-model',
                    params: $route.params
                  }
                : ''
            "
            class="clebex-item-content-wrapper"
          >
            <div
              class="clebex-section-input"
              v-if="selectedResourceModel && selectedResourceModel.name"
            >
              <label class="clebex-section-input-label">{{
                displayLabelName(
                  "resources",
                  "edit-resource",
                  "resource-model-id"
                )
              }}</label>
              <p class="text-label">
                {{ selectedResourceModel.name }}
              </p>
            </div>
            <div class="label" v-else>
              {{
                displayLabelName(
                  "resources",
                  "edit-resource",
                  "resource-model-id"
                )
              }}
            </div>
            <span class="follow-up-icons">
              <span class="follow-up-icon-item">
                <icon
                  icon="#cx-app1-arrow-right-12x12"
                  width="12"
                  height="12"
                />
              </span>
            </span>
            <Field
              name="resource_model_id"
              as="input"
              rules="required"
              type="hidden"
              v-model="resource_model_id"
            />
          </router-link>
        </li>
        <!-- Resource Propagate capacity -->
        <li class="clebex-item-section-item full-right-underline">
          <div class="clebex-item-content-wrapper">
            <dl class="clebex-item-dl justify-start">
              <dt class="clebex-item-dt">
                <div class="checkbox slide">
                  <input
                    id="addResourceRadioPropagateCapacity"
                    type="checkbox"
                    v-model="propagate_capacity"
                    :disabled="!canItem(resource.data, 'update')"
                  />
                  <label for="addResourceRadioPropagateCapacity"></label>
                </div>
              </dt>
              <dd class="clebex-item-dd justify-start">
                {{
                  displayLabelName(
                    "resources",
                    "edit-resource",
                    "propagate-capacity"
                  )
                }}
              </dd>
            </dl>
            <Field
              name="propagate_capacity"
              as="input"
              type="hidden"
              v-model="propagateFilter"
            />
          </div>
        </li>
        <!-- Resource Availability -->
        <li class="clebex-item-section-item full-right-underline">
          <router-link
            :to="{
              name: 'r_edit-resource-availability',
              params: $route.params
            }"
            class="clebex-item-content-wrapper"
          >
            <div class="label">
              {{
                displayLabelName("resources", "edit-resource", "availability")
              }}
            </div>
            <span class="follow-up-icons">
              <span class="follow-up-icon-item">
                <icon
                  icon="#cx-app1-arrow-right-12x12"
                  width="12"
                  height="12"
                />
              </span>
            </span>
          </router-link>
        </li>
        <!-- Resource Attributes -->
        <li class="clebex-item-section-item full-right-underline">
          <router-link
            :to="
              canItem(resource.data, 'update')
                ? {
                    name: 'r_edit-resource-select-resource-attributes',
                    params: $route.params
                  }
                : ''
            "
            class="clebex-item-content-wrapper"
          >
            <div
              class="clebex-section-input"
              v-if="
                selectedResourceAttributes && selectedResourceAttributes.length
              "
            >
              <label class="clebex-section-input-label">{{
                displayLabelName(
                  "resources",
                  "edit-resource",
                  "resource-attributes"
                )
              }}</label>
              <p class="text-label">
                {{ displayResourceAttributes() }}
              </p>
            </div>
            <div class="label" v-else>
              {{
                displayLabelName(
                  "resources",
                  "edit-resource",
                  "resource-attributes"
                )
              }}
            </div>
            <span class="follow-up-icons">
              <span class="follow-up-icon-item">
                <icon
                  icon="#cx-app1-arrow-right-12x12"
                  width="12"
                  height="12"
                />
              </span>
            </span>
          </router-link>
        </li>
        <!-- Resource Services -->
        <li class="clebex-item-section-item full-right-underline">
          <router-link
            :to="
              canItem(resource.data, 'update')
                ? {
                    name: 'r_edit-resource-select-resource-services',
                    params: $route.params
                  }
                : ''
            "
            class="clebex-item-content-wrapper"
          >
            <div
              class="clebex-section-input"
              v-if="selectedResourceServices && selectedResourceServices.length"
            >
              <label class="clebex-section-input-label">{{
                displayLabelName(
                  "resources",
                  "edit-resource",
                  "resource-service-items"
                )
              }}</label>
              <p class="text-label">
                {{ displayResourceServices() }}
              </p>
            </div>
            <div class="label" v-else>
              {{
                displayLabelName(
                  "resources",
                  "edit-resource",
                  "resource-service-items"
                )
              }}
            </div>
            <span class="follow-up-icons">
              <span class="follow-up-icon-item">
                <icon
                  icon="#cx-app1-arrow-right-12x12"
                  width="12"
                  height="12"
                />
              </span>
            </span>
          </router-link>
        </li>
        <!-- Resource QR Code -->
        <li
          class="clebex-item-section-item full-right-underline"
          v-if="resource.data.qrcode"
        >
          <router-link
            :to="
              canItem(resource.data, 'update')
                ? {
                    name: 'r_edit-resource-qr-code',
                    params: $route.params
                  }
                : ''
            "
            class="clebex-item-content-wrapper"
          >
            <div class="label">
              {{
                displayLabelName(
                  "resources",
                  "edit-resource",
                  "resource-qr-code"
                )
              }}
            </div>
            <span class="follow-up-icons">
              <span class="follow-up-icon-item">
                <icon
                  icon="#cx-app1-arrow-right-12x12"
                  width="12"
                  height="12"
                />
              </span>
            </span>
          </router-link>
        </li>
        <!-- Resource Image -->
        <li class="clebex-item-section-item full-right-underline">
          <router-link
            :to="
              canItem(resource.data, 'update')
                ? {
                    name: 'r_edit-resource-image',
                    params: $route.params
                  }
                : ''
            "
            class="clebex-item-content-wrapper"
          >
            <div class="label">
              {{ displayLabelName("resources.edit-resource.resource-image") }}
            </div>
            <span class="follow-up-icons">
              <span class="follow-up-icon-item">
                <div v-if="resourceImage" class="profile-photo-header-wrapper">
                  <div
                    class="photo-wrapper"
                    :style="{
                      'background-image': `url(${resourceImage})`
                    }"
                  ></div>
                </div>
                <icon
                  v-else
                  icon="#cx-app1-arrow-right-12x12"
                  width="12"
                  height="12"
                />
              </span>
            </span>
          </router-link>
        </li>
      </ul>
      <button type="submit" style="display: none;" ref="submitFormBtn">
        Submit form
      </button>
    </VeeForm>
  </mobile-screen>
  <router-view />
</template>

<script>
import MobileScreen from "@/layouts/MobileScreen";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import { mapState, mapActions, mapGetters } from "vuex";
import httpServiceAuth, { getLang } from "@/services/http-service";
import { apiEndpoints } from "@/services/constants";
import { errorHandler } from "@/services/error-handler";
import helpOnlineMixin from "@/services/mixins/help_online/help-online-mixin";

export default {
  name: "EditResource",
  mixins: [helpOnlineMixin],
  components: {
    MobileScreen,
    TopHeaderMenuWrapper
  },
  data() {
    return {
      formKey: 0,
      helpSlug: "resources-edit"
    };
  },
  created() {
    if (!this.resource) {
      this.$router.push({ name: "r_resources" });
    } else {
      this.setPaginationData(null);
      this.getResourceResourceFunctions(this.resource.data.id);
      this.getResourceFunctionTypes();
      this.setSelectedResourceLevel(null);
      this.getResourceAttributes();
      this.getResourceServices(this.resource.data.id);
      this.getResource({ id: this.resource.data.id });
      this.getResourceImage(this.resource.data.id);
    }
  },
  updated() {
    if (this.formKey === 10) {
      this.formKey = 0;
    } else {
      this.formKey++;
    }
  },
  watch: {
    resource() {
      if (this.formKey === 10) {
        this.formKey = 0;
      } else {
        this.formKey++;
      }
      this.setPaginationData(null);
      this.getResourceResourceFunctions(this.resource.data.id);
      this.getResourceFunctionTypes();
      this.setSelectedResourceLevel(null);
      this.getResourceAttributes();
      this.getResourceServices();
      this.getResourceImage(this.resource.data.id);
    }
  },
  computed: {
    ...mapState("resource", [
      "resource",
      "resourceFunctionTypes",
      "selectedResourceLevel",
      "selectedResourceType",
      "selectedResourceModel",
      "selectedResourceCapacityType",
      "resourceResourceFunctions",
      "selectedResourceAttributes",
      "selectedResourceServices",
      "resourceAttributes",
      "resourceTranslations"
    ]),
    ...mapGetters("resource", ["activeResourceFunctionTypes", "resourceImage"]),
    ...mapState("language", ["languages"]),
    capacity: {
      get() {
        return this.$store.state.resource.capacity;
      },
      set(val) {
        this.$store.commit("resource/setCapacity", val, { root: true });
      }
    },
    max_capacity: {
      get() {
        return this.$store.state.resource.maxCapacity;
      },
      set(val) {
        this.$store.commit("resource/setMaxCapacity", val, { root: true });
      }
    },
    propagate_capacity: {
      get() {
        return this.$store.state.resource.propagateCapacity;
      },
      set(val) {
        this.$store.commit("resource/setPropagateCapacity", val, {
          root: true
        });
      }
    },
    level_id() {
      if (this.selectedResourceLevel) {
        return this.selectedResourceLevel.id;
      } else if (this.resource && this.resource.data) {
        return this.resource.data.level_id;
      }
      return null;
    },
    resource_type_id() {
      if (this.selectedResourceType) {
        return this.selectedResourceType.id;
      }
      return null;
    },
    resource_model_id() {
      if (this.selectedResourceModel) {
        return this.selectedResourceModel.id;
      }
      return null;
    },
    resource_capacity_type_id() {
      if (this.selectedResourceCapacityType) {
        return this.selectedResourceCapacityType.id;
      }
      return null;
    },
    propagateFilter() {
      return Number(this.$store.state.resource.propagateCapacity);
    },
    resourceAttributeIds() {
      if (
        this.selectedResourceAttributes &&
        this.selectedResourceAttributes.length
      ) {
        return this.selectedResourceAttributes.map(attr => attr.id);
      }
      return [];
    },
    resourceServiceIds() {
      if (
        this.selectedResourceServices &&
        this.selectedResourceServices.length
      ) {
        return this.selectedResourceServices.map(attr => attr.id);
      }
      return [];
    },
    displayResourceName() {
      const { resource } = this;
      const locale = getLang();
      if (
        resource &&
        resource.data &&
        resource.data.translations &&
        resource.data.translations.length
      ) {
        const filtered = resource.data.translations.filter(
          item => item.locale === locale
        );
        if (filtered && filtered.length) {
          return filtered[0].attributes.name;
        } else {
          return resource.data.name;
        }
      } else {
        return "";
      }
    },
    displayResourceSavedName() {
      const { resourceTranslations } = this;
      const locale = getLang();
      if (resourceTranslations && resourceTranslations.length) {
        const filtered = resourceTranslations.filter(
          item => item.locale === locale
        );
        if (filtered && filtered.length) {
          return filtered[0].attributes.name;
        } else {
          return "";
        }
      } else {
        return "";
      }
    },
    hasTranslationsClause() {
      const { resource, languages, resourceTranslations } = this;
      if (languages && languages.data && languages.data.length) {
        return (
          languages.data.length === resourceTranslations.length ||
          (resource &&
            resource.data &&
            resource.data.translations &&
            resource.data.translations.length === languages.data.length)
        );
      }
      return false;
    }
  },
  methods: {
    ...mapActions("resource", [
      "setSelectedResourceLevel",
      "getResources",
      "getResource",
      "getResourceResourceFunctions",
      "getResourceAttributes",
      "getResourceServices",
      "setResourceResourceIds",
      "getResourceFunctionTypes",
      "getResourceImage"
    ]),
    ...mapActions("pagination", ["setPaginationData"]),
    submitForm(values) {
      if (
        values &&
        this.canItem(this.resource && this.resource.data, "update")
      ) {
        this.submitCompanyData(values);
      }
    },
    submitCompanyData(values) {
      this.$store.commit("loader/setScreenLoading", true, { root: true });
      const { id } = this.resource.data;
      values.day_period_id = null;
      if (this.resourceTranslations.length) {
        values.translations = this.resourceTranslations;
      } else if (
        this.resource.data.translations &&
        this.resource.data.translations.length
      ) {
        values.translations = this.resource.data.translations;
      }
      if (this.resourceAttributeIds) {
        values.resource_attribute_ids = this.resourceAttributeIds;
      }
      if (this.resourceServiceIds) {
        values.resource_service_ids = this.resourceServiceIds;
      }
      return httpServiceAuth
        .put(`${apiEndpoints.company.resources}/${id}`, values)
        .then(() => {
          this.getResources({ model_ids: null, reset: true });
          this.getResource({ id: id });
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response, this.findElement());
          }
        })
        .finally(() => {
          this.$store.commit("loader/setScreenLoading", false, {
            root: true
          });
        });
    },
    initSubmit() {
      const { submitFormBtn } = this.$refs;
      submitFormBtn.click();
    },
    displayResourceAttributes() {
      let display = "";

      this.selectedResourceAttributes.forEach((item, idx) => {
        display +=
          idx === this.selectedResourceAttributes.length - 1
            ? item.name
            : `${item.name}, `;
      });

      return (
        display ||
        this.displayLabelName(
          "resources",
          "edit-resource",
          "select-resource-attribute"
        )
      );
    },
    displayResourceServices() {
      let displayS = "";

      this.selectedResourceServices.forEach((item, idx) => {
        displayS +=
          idx === this.selectedResourceServices.length - 1
            ? item.name
            : `${item.name}, `;
      });

      return (
        displayS ||
        this.displayLabelName(
          "resources",
          "edit-resource",
          "select-resource-service"
        )
      );
    },
    displayResourceFunctions() {
      const { activeResourceFunctionTypes } = this;
      if (activeResourceFunctionTypes && activeResourceFunctionTypes.length) {
        const activated = [];
        activeResourceFunctionTypes.forEach(item => {
          if (item.active) {
            activated.push(item.name);
          }
        });
        return activated.join(", ");
      }
      return null;
    }
  },
  beforeUnmount() {
    this.$store.commit("resource/setResourceData", null, { root: true });
    this.$store.commit("resource/setResourceTranslations", [], {
      root: true
    });
    this.$store.commit("resource/setResourceImage", null, {
      root: true
    });
  }
};
</script>
